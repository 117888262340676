.vision__section {
    padding: 60px 0 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fafafa;
  }

  .vision__container {
    display: flex;
  }
  
  .vision__container-card {
    background: linear-gradient(45deg, #5786cc 0%, #648ee7 100%);
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
    width: 450px;
    height: 330px;
    text-decoration: none;
    border-radius: 4px;
    margin: 20px;
  }

  .vision__wrapper {
    margin: 0 auto;
  }
  
  .vision__heading {
    color: #1c2237;
    text-align: center;
  }

  .vision__heading::after {
    content: ''; 
    display: block; 
    width: 80px; 
    height: 4px; 
    background: #ff5e5e; 
    margin: 0 auto; 
    margin-top: 15px; 
    border-radius: 3px;
  }
  
  .vision__container-cardInfo {
    display: flex;
    flex-direction: column;
    height: 500px;
    padding: 22px;
    align-items: center;
    color: #fff;
  }
  
  .vision__container-cardInfo h2 {
    margin-bottom: 10px;
    text-align: center;
  }
  
  .vision__container-cardInfo p {
    margin: 6px 0;
    text-align: center;
    line-height: 1.6;
  }
  
  .vision__container-features {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

    .vision__container-img {
      width: 100px;
    }

  @media screen and (max-width: 960px) {
    .vision__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  
    .vision__container-card {
      width: 90%;
    }
  
    .vision__wrapper {
      margin: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .vision__container-cardInfo {
      display: flex;
      flex-direction: column;
      height: 320px;
      padding: 10px;
    }
    
    .vision__container-card:hover {
      transform: none;
    }
  }
  