.cleaning-section {
    color: #fff;
    padding: 120px 0;
}
  
.cleaning-row { 
    padding: 0 4px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
}

.cleaning-text-wrapper {
    padding: 0 40px 0 40px;
}

.cleaning-img {
    margin: 6px;
    padding: 0 20px 0 20px;
}

.cleaning-heading {
    color: #1c2237;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
  }

.cleaning-heading::after {
    content: ''; 
    display: block; 
    width: 80px; 
    height: 4px; 
    background: #ff5e5e; 
    margin: 0 auto; 
    margin-top: 15px; 
    border-radius: 3px;
}

.cleaning-subheading {
    text-align: center;
    color: #1c2237;
    margin: 20px;
}

.gallery__full-img {
    width: 529px;
    height: 240px;
  }

img {
    border: 0;
}
  
@media screen and (max-width: 800px) {
    .column {
        flex: 50%;
        max-width: 50%;
    }
}

@media screen and (max-width: 600px) {
    .column {
        flex: 100%;
        max-width: 100%;
    }
}