.choose__section {
    padding: 60px 0 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #ebebeb;
  }
  
  .choose__container-card {
    background: linear-gradient(45deg, #0660e9 0%, #276afb 100%);
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
    width: 220px;
    height: 400px;
    text-decoration: none;
    border-radius: 4px;
  }
  
  .choose__container-card:nth-child(2), .choose__container-card:nth-child(4) {
    background: #242424;
  }

  .choose__container-card:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
  }
  
  .choose__container-card:nth-child(2), .choose__container-card:nth-child(4) {
    margin: 0 20px;
  }

  .choose__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .choose__wrapper {
    margin: 0 auto;
    text-align: center;
  }
  
  .choose__heading {
    color: #1c2237;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .choose__heading::after {
    content: ''; 
    display: block; 
    width: 80px; 
    height: 4px; 
    background: #ff5e5e; 
    margin: 0 auto; 
    margin-top: 15px; 
    border-radius: 3px;
  }
  
  .choose__container-cardInfo {
    display: flex;
    flex-direction: column;
    height: 500px;
    padding: 22px;
    color: #fff;
  }
  
  .choose__container-cardInfo h3 {
    margin-bottom: 10px;
    font-size: 22px;
    text-align: center;
  }
  
  .choose__container-cardInfo p {
    margin: 6px 0;
    text-align: center;
  }
  
  .choose__container-features {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
 
  .choose__container-img {
    width: 80%;
    text-align: center;
  }

  @media screen and (max-width: 960px) {
    .choose__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  
    .choose__container-card {
      width: 90%;
      height: 45%;
      margin-bottom: 16px;
    }

    .choose__container-cardInfo {
      height: 300px;
      align-items: center;
    }

    .choose__container-card:nth-child(2) {
      transform: scale(1);
      background: #242424;
      margin-bottom: 16px;
    }
  
    .choose__container-card:hover {
      transform: none;
    }

    .choose__container-img {
      width: 40%;
    }
  }
  