.contactInfo__section {
    padding: 60px 0 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fafafa;
  }
  
  .contactInfo__container-card {
    width: 350px;
    text-decoration: none;
    border-radius: 4px;
    margin-right: 20px;
  }

  .contactInfo__container {
    display: flex;
  }
  
  .contactInfo__wrapper {
    margin: 0 auto;
    justify-content: center;
  }
  
  .contactInfo__heading {
    color: #1c2237;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
  }

  .contactInfo__heading::after {
    content: ''; 
    display: block; 
    width: 80px; 
    height: 4px; 
    background: #ff5e5e; 
    margin: 0 auto; 
    margin-top: 15px; 
    border-radius: 3px;
  }
  
  .contactInfo__container-cardInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contactInfo__container-cardInfo h3, a {
    margin: 10px;
    text-align: center;
    text-decoration: none;
  }

  .contactInfo__container-map {
    width: 550px;
    height: 400px; 
    border: 0;
  }

  .contactInfo__container-img {
    width: 45px;
    margin: 4px;
  }

  @media screen and (max-width: 960px) {
    .contactInfo__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  
    .contactInfo__container-card:hover {
      text-align: center;
    }
  
    .contactInfo__wrapper {
      margin: 30px;
    }
 
    .contactInfo__container-card:hover {
      transform: none;
    }
  }
  