.home__hero-section {
    color: #fff;
    padding: 120px 0;
  }
  
  .home__hero-row {
    align-items: center;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
  }

  .home__hero-text-wrapper {
    padding: 0 40px 0 40px;
  }
  
  .top-line {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
    text-align: center;
  }
  
  .heading {
    margin-bottom: 30px;
    font-size: 48px;
    font-weight: 600;
    color: #f7f8fa;
    text-align: center;
  }

  .subheading {
    color: #1c2237;
    text-align: center;
    margin-bottom: 24px;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .subheading::after {
    content:''; 
    display: block; 
    width: 80px; 
    height: 4px; 
    background: #ff5e5e; 
    margin:0 auto; 
    margin-top: 15px; 
    border-radius: 3px;
  }

  .dark {
    color: #2c3b71;
  }
  
  .darkBg {
    background: -webkit-linear-gradient(to right, #2c3b71, #2b4398);
    background: linear-gradient(to right, #2c3b71, #2b4398);
    width: 100%;
    height: 70vh;
  }
  
  .home__hero-subtitle {
    margin-bottom: 60px;
    padding: 0 15px 0 15px;
    font-size: 18px;
    font-weight: lighter;
    line-height: 24px;
    text-align: center;
  }

  .home__hero-img {
    text-align: center;
    padding: 0 20px 0 20px;
  }

  img {
    border: 0;
    max-width: 100%;
  }

  .box-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .box-area li {
    position: absolute;
    display: block;
    list-style: none;
    width: 25px;
    height: 25px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 20s linear infinite;
    bottom: -150px;
  }

  .box-area li:nth-child(1) {
    left: 86%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }

  .box-area li:nth-child(2) {
    left: 12%;
    width: 30px;
    height: 30px;
    animation-delay: 1.5s;
    animation-duration: 6s;
  }

  .box-area li:nth-child(3) {
    left: 70%;
    width: 100px;
    height: 100px;
    animation-delay: 4s;
  }

  .box-area li:nth-child(4) {
    left: 42%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 8s;
  }

  .box-area li:nth-child(5) {
    left: 65%;
    width: 40px;
    height: 40px;
    animation-delay: 0s;
  }

  .box-area li:nth-child(6) {
    left: 15%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }

  @keyframes animate {
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: translateY(-800px) rotate(360deg);
      opacity: 0;
    }
  }
  
  @media screen and (max-width: 991px) {
    .container {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .home__hero-text-wrapper {
      padding: 0 15px 0 15px;
    }

    .home__hero-subtitle {
      padding: 0 5px 0 5px;
    }

  }
  