.listing__section {
    padding: 60px 0 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fafafa;
  }
  
  .listing__container-card {
    background: linear-gradient(45deg, #648ee7 0%, #648ee7 100%);
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
    width: 350px;
    height: 300px;
    text-decoration: none;
    border-radius: 4px;
    margin-right: 20px;
  }

  .listing__container {
    display: flex;
  }
  
  .listing__wrapper {
    margin: 0 auto;
  }
  
  .listing__heading {
    color: #1c2237;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
  }

  .listing__heading::after {
    content: ''; 
    display: block; 
    width: 80px; 
    height: 4px; 
    background: #ff5e5e; 
    margin: 0 auto; 
    margin-top: 15px; 
    border-radius: 3px;
  }

  .listing__container-img {
    display: inline;
  }

  .listing__wrapper h4 {
    padding: 20px;
    text-align: center;
  }
  
  .listing__container-cardInfo {
    display: flex;
    flex-direction: column;
    padding: 22px;
    align-items: center;
    color: #fff;
  }
  
  .listing__container-cardInfo h3 {
    margin-bottom: 10px;
    font-size: 22px;
    text-align: center;
  }
  
  .listing__container-cardInfo ul {
    margin: 6px 0;
    text-align: center;
    line-height: 1.8;
  }
  
  .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-flow: dense;
  }

  .gallery-img {
    margin-top: 20px;
    width: 212px;
    height: 240px;
  }

  @media screen and (max-width: 960px) {
    .listing__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  
    .listing__container-card {
      width: 90%;
    }
  
    .listing__wrapper {
      margin: 0 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .listing__container-card:nth-child(2) {
      transform: scale(1);
      background: #242424;
      margin: 24px;
    }
  
    .listing__container-card:hover {
      transform: none;
    }
  }
  