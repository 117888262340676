.history__section {
    padding: 60px 0 120px;
  }

  .history__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
    
  .history__heading {
    color: #1c2237;
    margin: 16px;
    text-align: center;
  }

  .history__description {
    color: #1c2237;
    line-height: 1.6;
    padding-bottom: 8px;
  }
  
  .history__heading::after {
    content:''; 
    display: block; 
    width: 80px; 
    height: 4px; 
    background: #ff5e5e; 
    margin:0 auto; 
    margin-top: 15px; 
    border-radius: 3px;
  } 

  .history-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 12px;
    text-align: left;
    width: 500px;
    box-sizing: border-box;
  }
  
  .history-container {
    margin: 0 10px 0 10px;
  }

  .history-img {
    width: 100%;
  }
  
  @media screen and (max-width: 960px) {
    .history__wrapper {
      display: inline;
    }

    .history-items {
      text-align: justify;
      width: fit-content;
      margin: 1.5rem;
    }

    .history__description {
      padding: 0.5rem;
    }
    
  }
  