.disinfection-section {
    color: #fff;
    padding: 120px 0;
}
  
.disinfection-row { 
    padding: 0 4px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
}

.disinfection-text-wrapper {
    padding: 0 40px 0 40px;
}

.disinfection-img {
    text-align: center;
}

.image-size {
    margin: 6px;
    width: 250px;
    height: 300px;
}
.disinfection-heading {
    color: #1c2237;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
  }

.disinfection-heading::after {
    content: ''; 
    display: block; 
    width: 80px; 
    height: 4px; 
    background: #ff5e5e; 
    margin: 0 auto; 
    margin-top: 15px; 
    border-radius: 3px;
}

.disinfection-subheading {
    text-align: center;
    color: #1c2237;
    margin: 20px;
}
  
@media screen and (max-width: 800px) {
    .column {
        flex: 50%;
        max-width: 50%;
    }
}

@media screen and (max-width: 600px) {
    .column {
        flex: 100%;
        max-width: 100%;
    }
}