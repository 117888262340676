.stacked__container {
    display: flex;
    justify-content: center;
    align-items: center;    
}

.stacked {
    height: 220px;
    width: 220px;
    margin: 20px;
    padding-bottom: 20px;
    transition: all 0.5s ease;
}

.stacked img {
    perspective: 1000;
    opacity: 0.8;
    border-radius: 50%;
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    box-shadow: 0 5px 5px #5f5f5f;
    transition: all 0.4s ease;
}

.stacked p {
    text-align: center;
    margin-bottom: 10px;
    text-decoration: none;
    color: #242424;
}

.stacked__wrapper {
    flex-direction: column;
    margin: 0 auto;
    padding: 60px 0 120px;
}
  
.stacked__heading {
    color: #1c2237;
    margin: 16px;
    text-align: center;
}

.stacked__heading::after {
    content:''; 
    display: block; 
    width: 80px; 
    height: 4px; 
    background: #ff5e5e; 
    margin:0 auto; 
    margin-top: 15px; 
    border-radius: 3px;
}

.stacked__subheading {
    text-align: center;
    padding: 25px;
}

.stacked:hover {
    margin-top: 0;
}

.stacked img:hover {
    box-shadow: 0 20px 20px #5f5f5f;
    opacity: 1;
    border-radius: 50%;
    height: 100%;
    transform: rotate(-20deg);
}

@media screen and (max-width: 960px) {
    .stacked__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }   
    
    .stacked {
        height: 300px;
    }

    .stacked__subheading {
        text-align: center;
        padding: 11px;
    }
  }
  