:root{
  --primary: #fff;
}

.btn{
  padding: 8px 2px;
  border-radius:  5px;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.btn--primary{
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}

.btn--outline{
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3 ease-out;
}

.btn-medium{
  padding: 8px 20px;
  font-size: 18px;
}

.btn-large{
  padding: 12px 26px;
  font-size: 20px;
}

.btn-mobile{
  text-align: center;
  border-radius: 4px;
  width: 80%;
  text-decoration: none;
  font-size: 24px;
  background-color: transparent;
  color: #fff;
  padding: 14px 20px;
  border: 1px solid #fff;
  transition: all 0.3 ease-out;
}

.btn--wide{
  padding: 12px 60px;
  font-size: 16px;
}

.btn--large:hover,
.btn--medium:hover,
.btn--mobile:hover{
  transition: all 0.3 ease-out;
  background-color: #fff;
  color: #242424;
}

.btn--wide:hover{
  color: #fff;
  background-color: skyblue;
  transition: all 0.2s ease-out;
}

.skyblue{
  background-color:skyblue;
  color: white;
  border:none
}

.primary{
  background-color: #242424;
  color: white;
  border:none
}

.primary:hover{
  background-color: #1c2237;
  color: white;
  border:none
}

.blue{
  background-color: #2279fa;
  color: white;
  border:none
}

.black{
  background-color:  white;
  color: black;
  border:none
}

.btn-link{
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;


}