.contact_us{
    background-color: #f1f1f1;
    padding: 80px 0px;
}

.contact_inner{
    background-color: #fff;
    position: relative;
    box-shadow: 20px 22px 44px #cccc;
    border-radius: 25px;
}

.contact_field{
    padding: 30px 140px 30px 140px;
}

.contact_field .form-control{
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #ccc;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 5px;
}

.contact_field h3{
  margin-bottom: 10px;
}

.contact_field p{
  color: red;
}

.contact_field .form-control:focus{
    box-shadow: none;
    outline: none;
    border-bottom: 2px solid #1325e8;
}

.contact_field .form-control::placeholder{
    font-size: 14px;
    letter-spacing: 1px;
}

  @media screen and (max-width: 960px) {
    .contactForm__container {
      align-items: center;
      width: 100%;
    }
  
    .contactForm__container-card:hover {
      text-align: center;
    }
  
    .contactForm__wrapper {
      margin: 30px;
    }
 
    .contact_field{
      padding: 10px 20px 10px 20px;
    }

    .contactInfo__container-map{
      width: 100%;
  }
  }
  