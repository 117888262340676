.dropdown-menu {
  background: red;
  width: 200px;
  position: absolute;
  top: 70px;
  list-style: none;
  text-align: start;
}

.dropdown-menu li {
  background: #0047bb;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #245ebb;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}
  
  /* @media screen and (max-width: 960px) {
    .fa-caret-down {
      display: none;
    }
  } */


  /* For Mobile DropDown */
  @media screen and (max-width: 960px) {
    .dropdown-menu {
      width: 100%;
      position:absolute;
      top:170px;
      list-style: none;
      text-align: center;
    }
  }
  