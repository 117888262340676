.whatsapp-button{
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 99;
    background-color: #25d366;
    border-radius: 50px;
    color: #ffffff;
    text-decoration: none;
    width: 50px;
    height: 50px;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 0px 25px -6px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 25px -6px rgba(0,0,0,1);
    box-shadow: 0px 0px 25px -6px rgba(0,0,0,1);
    animation: effect 5s infinite ease-in;
  }

@keyframes effect {
    20%, 100% {
        width: 50px;
        height: 50px;
        font-size: 30px;
    }
    0%, 10%{
        width: 55px;
        height: 55px;
        font-size: 35px;
    }
    5%{
        width: 50px;
        height: 50px;
        font-size: 30px;
    }
}